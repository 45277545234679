@import '../variables';

.primary-color {
    color: $primary-brand-color !important;
}

.bg-primary-brand {
    background-color: $primary-background-color;
}

.bg-secondary-brand {
    background-color: $secondary-color !important;
}

.text-black {
    color: black;
}

.bd-bottom {
    border-bottom: 1px solid rgb(65, 65, 65);
}

.bd-top {
    border-top: 1px solid rgb(65, 65, 65);    
}

.icon-size {
    width: $default-icon-size !important;
    height: $default-icon-size !important;
    color: white;
}

.icon-lg {
    width: 45px;
    height: 45px;
    color: white;
}

.icon-md {
    width: 35px;
    height: 35px;
}

.icon-sm {
    width: 25px;
    height: 25px;
    color: white;    
}

.clear-icon {
    background-color: transparent;
    border: 0;
}

.gear-icon {
    display: flex;
    margin-top: 6px;
}

.btn-large, .transparent-btn-large {
    width: 100%;
    font-size: 1.4rem !important;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-large {
    background-color: $primary-brand-color;
    color: $secondary-color !important;
}

.transparent-btn-large {
    background-color: transparent;
    border: 3px solid $primary-brand-color;
    color: $primary-brand-color;
}



//bootstrap adjustments

.btn-outline-primary {
    border-color: $primary-brand-color;
    color: $primary-brand-color;
    border-width: 2px;
    font-size: 1.2rem !important;
    width: 50%;
    padding: 10px 0 10px 0;
}

 .btn-primary {
    background-color: $primary-brand-color;
    color: black;
    border: 0;
}  

.btn {
    font-weight: 900;
    font-size: 1.1rem;
}

  

body .btn:active, .btn:focus  {
    background-color: $primary-brand-color !important;
    border-color: $primary-brand-color !important;
    color:black !important;
}


//defaults
div#root {
    min-height: 100vh;
}

p {
    color: white;
}

.modal {

    .modal-dialog {
        width:85%;
        left: 5%;
        top: 10%;
    }

    .modal-background {
        background-color: rgba(78, 78, 78, 0.185);
    }
    
    .modal-body {
        padding: 0;
    }
    
    .profile-page {
        height: auto;
    }
}


