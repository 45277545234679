
//colors
$primary-brand-color: #6BD9D9;
$primary-background-color: #050505;
$extra-dark-grey: #141414;
$secondary-color: #1d1d1d;
$bg-qr: #faf5f5;

//Typography

//Other
$border-radius-primary: 0.75rem;
$border-radius-rounder: 10px;
$border-radius-sharper: 5px;

$border-color: #363737;

$default-icon-size: 25px;