@import '../variables';
@import '../utility/mixins.scss';

.profile-page {
  
  .user-background-crop {
    width: 100%;  // Your desired width
    height: 140px;  // Your desired height
    overflow: hidden;
    position: relative;
  }

  .cropped-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;  // Ensures the width of the image matches the width of the container
    height: auto;  // Maintains aspect ratio
  }

  .profile-picture {
    bottom: -50%;  // Adjust this value to position the profile picture as you like
    left: 50%;
    transform: translate(-50%, -130%);
    z-index: 100;  // To make sure the profile picture is above the card image
  }


  .user-name {
    position:relative;
    top: -10%;
  }

  .edit-icon {
    color: $primary-brand-color;
    margin: 10px;
    width: 30px;
    height: auto;
  }

  tbody, h5 {
    color: white !important;
  }

  tr {
    width: 100%;
  }

  td.full-width {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  //Bootstrap tweaks
  .card {
    display: block !important; 
    padding: 0px;
    background-color: $secondary-color;
  }

  .table-bordered td, .table.table-bordered, .table thead th  { 
    border: 0px solid transparent !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary-brand-color;
  }


  .btn-primary {
    width: 100%;
    padding: 0px 45px 0px 45px;
  }

}

.profile-modal {
  

  .modal-dialog {
    width: 100%;
    height: 1;
    left: 0;
    margin: 0;
  }

  .modal-content {
    background-color: $secondary-color;
  }
}

.link-card {

  border-bottom: 20px solid black;

}

.edit-profile {
  
  input {
    background-color: $secondary-color;
    border: 0;
    border-radius: $border-radius-rounder;
    color: rgb(216, 216, 216);
    padding: 8px;
    padding-left: 12px;
    width: 100%;
  }


}