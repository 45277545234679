.custom-container {
    cursor: pointer;
  }
  
  .title {
    text-align: center;
  }
  
  .custom-input-group-text {
    cursor: pointer;
  }
  
  .custom-input-group {
    cursor: pointer;
  }
  
  .custom-password-toggle {
    cursor: pointer;
    text-decoration: underline;
    border: 1px solid var(--warning); /* if warning is a predefined CSS variable */
  }
  
  .custom-checkbox-container {
    cursor: pointer;

  }
  
  .submit-btn-container {
    cursor: pointer;
  }
  
  .google-btn {
    cursor: pointer;
  }
  
  .google-icon {
    cursor: pointer;
  }
  
  .login-link-container {
    cursor: pointer;
  }
  
  .login-link {
    cursor: pointer;
  }
  